import { ApolloClient } from '@apollo/client'
import { ApolloContext } from '@/services/types/Gql'
import { StatsResponse } from '~/services/types/Stats'
import getStats from '@/graphql/stats/getStats.gql'
import getCampaignStats from '@/graphql/stats/getCampaignStats.gql'
import getTimedeltaCampaignStats from '@/graphql/stats/getTimedeltaCampaignStats.gql'

interface IStatsGql {
  $apollo: ApolloClient<any>
  getGlobalAverage: (tenantId: string, phoneNumberIds: Array<string>, interval: string, startDatetime: string, endDatetime: string) => Promise<StatsResponse>
  getStats: (tenantId: string, phoneNumberIds: Array<string>, interval: string, startDatetime: string, endDatetime: string) => Promise<StatsResponse>
  getAdminStats: (tenantId: string, phoneNumberIds: Array<string>, interval: string, startDatetime: string, endDatetime: string) => Promise<StatsResponse>
  getAdminResponseTimesStats: (tenantId: string, phoneNumberIds: Array<string>, interval: string, startDatetime: string, endDatetime: string) => Promise<StatsResponse>
  getAdminTotalResponseTimesStats: (tenantId: string, phoneNumberIds: Array<string>, interval: string, startDatetime: string, endDatetime: string) => Promise<StatsResponse>
  getCampaignStats: (tenantId: string, phoneNumberIds: Array<string>, startDatetime: string, endDatetime: string) => Promise<StatsResponse>
  getTimedeltaCampaignStats: (tenantId: string, phoneNumberIds: Array<string>, startDatetime: string, endDatetime: string) => Promise<StatsResponse>
}

export default class StatsGql implements IStatsGql {
  $apollo: ApolloClient<any>

  constructor (context: ApolloContext) {
    this.$apollo = context.$apollo
  }

  getGlobalAverage (tenantId: string, phoneNumberIds: Array<string>, interval: string, startDatetime: string, endDatetime: string) {
    return this.$apollo.query({
      query: getStats,
      fetchPolicy: 'no-cache',
      variables: {
        tenantId,
        phoneNumberIds,
        events: ['INCOMING_MESSAGE', 'INITIAL_RESPONSE', 'OUTGOING_MESSAGE', 'RESPONSE', 'SUPPLEMENTARY_RESPONSE'],
        aggregateFunctions: ['COUNT', 'AVERAGE', 'MEDIAN'],
        col: 'INTVAL',
        groupBy: ['EVENT', interval?.toUpperCase()],
        startDatetime,
        endDatetime
      }
    }).then(result => result?.data as StatsResponse)
  }

  getStats (tenantId: string, phoneNumberIds: Array<string>, interval: string, startDatetime: string, endDatetime: string) {
    return this.$apollo.query({
      query: getStats,
      fetchPolicy: 'no-cache',
      variables: {
        tenantId,
        phoneNumberIds,
        events: ['INCOMING_MESSAGE', 'INITIAL_RESPONSE', 'OUTGOING_MESSAGE', 'RESPONSE', 'SUPPLEMENTARY_RESPONSE'],
        aggregateFunctions: ['COUNT', 'AVERAGE'],
        col: 'INTVAL',
        groupBy: ['EVENT', 'PHONE_NUMBER', interval?.toUpperCase()],
        startDatetime,
        endDatetime
      }
    }).then(result => result?.data as StatsResponse)
  }

  getAdminStats (tenantId: string, phoneNumberIds: Array<string>, interval: string, startDatetime: string, endDatetime: string) {
    return this.$apollo.query({
      query: getStats,
      fetchPolicy: 'no-cache',
      variables: {
        tenantId,
        phoneNumberIds,
        events: [
          'INCOMING_MESSAGE',
          'INITIAL_RESPONSE',
          'CONVERSATION',
          'OUTGOING_MESSAGE',
          'RESPONSE',
          'SUPPLEMENTARY_RESPONSE',
          'INCOMING_STOP_WORD',
          'OUTGOING_MESSAGE_FAILURE'
        ],
        aggregateFunctions: ['COUNT', 'AVERAGE'],
        col: 'INTVAL',
        groupBy: ['EVENT', 'PHONE_NUMBER', interval?.toUpperCase()],
        startDatetime,
        endDatetime
      }
    }).then(result => result?.data as StatsResponse)
  }

  getAdminResponseTimesStats (tenantId: string, phoneNumberIds: Array<string>, interval: string, startDatetime: string, endDatetime: string) {
    return this.$apollo.query({
      query: getStats,
      fetchPolicy: 'no-cache',
      variables: {
        tenantId,
        phoneNumberIds,
        events: ['INITIAL_RESPONSE', 'CONVERSATION', 'RESPONSE'],
        aggregateFunctions: ['COUNT', 'AVERAGE', 'MEDIAN'],
        col: 'TIMEDELTA',
        groupBy: ['EVENT', 'PHONE_NUMBER', interval?.toUpperCase()],
        startDatetime,
        endDatetime
      }
    }).then(result => result?.data as StatsResponse)
  }

  getAdminTotalResponseTimesStats (tenantId: string, phoneNumberIds: Array<string>, interval: string, startDatetime: string, endDatetime: string) {
    return this.$apollo.query({
      query: getStats,
      fetchPolicy: 'no-cache',
      variables: {
        tenantId,
        phoneNumberIds,
        events: ['INITIAL_RESPONSE', 'CONVERSATION', 'RESPONSE'],
        aggregateFunctions: ['COUNT', 'AVERAGE', 'MEDIAN'],
        col: 'TIMEDELTA',
        groupBy: ['EVENT', interval?.toUpperCase()],
        startDatetime,
        endDatetime
      }
    }).then(result => result?.data as StatsResponse)
  }

  getCampaignStats (tenantId: string, phoneNumberIds: Array<string>, startDatetime: string, endDatetime: string) {
    return this.$apollo.query({
      query: getCampaignStats,
      fetchPolicy: 'no-cache',
      variables: {
        tenantId,
        phoneNumberIds,
        startDatetime,
        endDatetime
      }
    }).then(result => result?.data as StatsResponse)
  }

  getTimedeltaCampaignStats (tenantId: string, phoneNumberIds: Array<string>, startDatetime: string, endDatetime: string) {
    return this.$apollo.query({
      query: getTimedeltaCampaignStats,
      fetchPolicy: 'no-cache',
      variables: {
        tenantId,
        phoneNumberIds,
        startDatetime,
        endDatetime
      }
    }).then(result => result?.data as StatsResponse)
  }
}
